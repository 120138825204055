import React from "react";
import { matchPath, Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import { useUser } from "../../actions/user.actions";
import { RouteType } from "../../routes";

const MainContainer = React.lazy(() => import("../../components/Layout/MainContainer"));
const ContractorJoinPageLazy = React.lazy(() => import("./ContractorJoinPage"));
const ContractorPageLazy = React.lazy(() => import("./ContractorPage"));
const ContractorTendersLazy = React.lazy(() => import("./ContractorTenders"));
const ContractorTenderPageLazy = React.lazy(() => import("./ContractorTenderPage"));
const ContractorTendersArchiveLazy = React.lazy(() => import("./ContractorTendersArchive"));

export const contractorRoutes: RouteType[] = (() => {
  return [
    {
      title: "Contractor.ContractorJoinTitle",
      path: ":contractorId/join/:token",
      element: ContractorJoinPageLazy,
      allowAnonymous: true,
    },
    {
      title: "Contractor.ContractorTitle",
      path: ":contractorId",
      element: ContractorPageLazy,
    },
    {
      title: "Contractor.Tenders",
      path: ":contractorId/tenders",
      element: ContractorTendersLazy,
    },
    {
      title: "Contractor.TendersArchive",
      path: ":contractorId/tenders/archive",
      element: ContractorTendersArchiveLazy,
      parentPath: "/contractors/:contractorId/tenders",
    },
    {
      title: "Contractor.Tender",
      path: ":contractorId/tenders/:tenderId",
      parentPath: "/contractors/:contractorId/tenders",
      element: ContractorTenderPageLazy,
    },
  ];
})();

const ContractorLayout = (): JSX.Element => {
  const { pathname } = useLocation();
  const { t } = useTranslation("translation");
  const [{ uiMode }, userActions] = useUser();

  const getPageTitleFromUrl = (pathname: string) => {
    const currentRoute = contractorRoutes.find((r) => {
      if (matchPath({ path: "/contractors/" + r.path }, pathname)) {
        return true;
      }

      return false;
    });

    return currentRoute?.title ? t(currentRoute.title) : "";
  };

  React.useEffect(() => {
    if (uiMode !== "contractor") {
      userActions.setUiMode("contractor");
    }
  }, [pathname]);

  return (
    <MainContainer title={getPageTitleFromUrl(pathname)}>
      <Container maxWidth="md">
        <Outlet />
      </Container>
    </MainContainer>
  );
};

export default ContractorLayout;
