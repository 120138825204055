import { combineReducers } from "redux";
import user, { initialState as userState } from "./users.reducer";
import club, { initialState as clubState } from "./club.reducer";
import unit, { initialState as unitState } from "./unit.reducer";
import building, { initialState as buildingState } from "./building.reducer";
import map, { initialState as mapState } from "./map.reducer";
import postbox, { initialState as postboxState } from "./postbox.reducer";
import admin, { initialState as adminState } from "./admin/admin.reducer";
import adminLanding, { initialState as adminLandingState } from "./admin/admin-landing.reducer";
import adminCo2Plan, { initialState as adminCo2PlanState } from "./admin/admin.co2plan.reducer";
import landing, { initialState as landingState } from "./landing.reducer";
import newsstream, { initialState as newsstreamState } from "../pages/NewsStream/newsstream.reducer";
import servicebook, { initialState as servicebookState } from "./servicebook.reducer";
import tender, { initialState as tenderState } from "./tender.reducer";
import workers, { initialState as workersState } from "../pages/Workers/workers.reducer";
import document, { initialState as documentState } from "./document.reducer";
import family, { initialState as familyState } from "./family.reducer";
import subscriptions, { initialState as subscriptionsState } from "./subscriptions.reducer";
import comment, { CommentInitialState as commentState } from "./comment.reducer";
import co2, { CO2InitialState as co2State } from "../pages/ClimatePlan/shared/co2.reducer";
import navigation, { initialState as navigationState } from "../pages/Overview/navigation.reducer";
import adminNavigation, {
  initialState as adminNavigationState,
} from "../pages/AdminPanel/pages/OverviewNavigation/admin.navigation.reducer";
import adminPartners, {
  initialState as adminPartnersState,
} from "../pages/AdminPanel/pages/OverviewNavigation/admin.partners.reducer";
import behaviourRules, { initialState as behaviourRulesState } from "./behavior-rules.reducer";
import pricePredictions, {
  initialState as pricePredictionsState,
} from "../pages/AdminPanel/pages/PricePredictions/admin.price-prediction.reducer";
import { UserConstants } from "../constants";
import adminNeighborGroup, { initialState as adminNeighborGroupState } from "./admin/admin.neighborgroup.reducer";
import neighborGroup, { initialState as neighborGroupState } from "./neighborgroup.reducer";
import chat, { initialState as chatState } from "./chat.reducer";

const initialState = {
  user: userState,
  club: clubState,
  unit: unitState,
  building: buildingState,
  map: mapState,
  postbox: postboxState,
  adminLanding: adminLandingState,
  adminCo2Plan: adminCo2PlanState,
  landing: landingState,
  newsstream: newsstreamState,
  servicebook: servicebookState,
  tender: tenderState,
  admin: adminState,
  document: documentState,
  workers: workersState,
  family: familyState,
  subscriptions: subscriptionsState,
  comment: commentState,
  co2: co2State,
  navigation: navigationState,
  adminNavigation: adminNavigationState,
  adminPartners: adminPartnersState,
  behaviourRules: behaviourRulesState,
  pricePredictions: pricePredictionsState,
  adminNeighborGroup: adminNeighborGroupState,
  neighborGroup: neighborGroupState,
  chat: chatState,
};

const combinedReducer = combineReducers({
  user,
  club,
  unit,
  building,
  map,
  postbox,
  adminLanding,
  adminCo2Plan,
  landing,
  newsstream,
  servicebook,
  tender,
  admin,
  document,
  workers,
  family,
  subscriptions,
  comment,
  co2,
  navigation,
  adminNavigation,
  adminPartners,
  behaviourRules,
  pricePredictions,
  adminNeighborGroup,
  neighborGroup,
  chat,
});

//TODO: make initial state if needed
const rootReducer = (state: any, action: any) => {
  if (action.type === UserConstants.LOGOUT) {
    state = { ...initialState, landing: state.landing, navigation: state.navigation };
  }
  return combinedReducer(state, action);
};

export default rootReducer;
