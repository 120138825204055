import React, { Suspense, useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import LoadingOverlay from "../components/LoadingOverlay";
import { useUser } from "../actions";
import OfflinePage from "../pages/OfflinePage";
import { Dialog } from "@mui/material";
import ContactPage from "../pages/Contact/ContactPage";
import { UserConstants } from "../constants/user.constants";
import CommuneClimatePlanLayout, { klimaplanRoutes } from "../pages/MyClimatePlanPage";
import NotFoundPage from "../pages/NotFound/NotFoundPage";
import { adminRoutes } from "../pages/AdminPanel";
import { UserType } from "../constants/enums";
import { buildingRoutes, OldInviteBuildingRedirect } from "../pages/Building/BuildingLayout";
import { propertyRoutes } from "../components/Layout/PropertyLayout";
import BaseLayout, { pagesRoutes } from "../components/Layout/BaseLayout";
import MainContainer from "../components/Layout/MainContainer";
import { contractorRoutes } from "../pages/Contractor/ContractorLayout";

const AdminLayout = React.lazy(() => import("../pages/AdminPanel"));
const BuildingLayout = React.lazy(() => import("../pages/Building/BuildingLayout"));
const PropertyLayout = React.lazy(() => import("../components/Layout/PropertyLayout"));
const ContractorLayout = React.lazy(() => import("../pages/Contractor/ContractorLayout"));

export type RouteType = {
  title?: string;
  titleOptions?: any;
  path: string;
  parentPath?: string;
  element: React.LazyExoticComponent<() => React.JSX.Element> | (() => React.JSX.Element) | any;
  hideMenu?: boolean;
  fullScreen?: boolean;
  allowAnonymous?: boolean;
  hideElements?: boolean;
};

const RoutesMap = (): JSX.Element => {
  const [{ user, isMaintenance, needToAcceptTerms }, userActions] = useUser();
  const isAdmin = !!user?.Role && user?.Role >= UserType.ExternalManager;
  const isAuthorized = user?.Role !== undefined;

  const { pathname } = useLocation();
  useEffect(() => {
    userActions._getBasicData();

    const beforeInstallPromptHandler = (e: Event) => {
      e.preventDefault();
      userActions.setPwaOptions(e);
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);

    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstallPromptHandler);
    };
  }, []);

  useEffect(() => {
    const sendAnalyticData = (e: BeforeUnloadEvent) => {
      userActions.dispatchMessage(UserConstants.USER_REGISTERED_AND_STOP);
    };
    if (user) {
      const currentDate = new Date();
      const isNewUser = currentDate.getTime() / 3600000 - new Date(user.Created).getTime() / 3600000 <= 24;

      if (isNewUser && pathname === "/overview") {
        window.addEventListener("beforeunload", sendAnalyticData);
      } else {
        window.removeEventListener("beforeunload", sendAnalyticData);
      }
    }
  });

  useLayoutEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }, [pathname]);

  return (
    <Suspense fallback={<LoadingOverlay loading />}>
      <Dialog open={isMaintenance || false} fullScreen fullWidth>
        <OfflinePage />
      </Dialog>
      <Dialog
        key={pathname}
        open={(pathname != "/profile" && needToAcceptTerms && user !== null) || false}
        fullScreen
        fullWidth
      >
        <MainContainer title="Pages.Contact.TermsUpdated">
          <ContactPage />
        </MainContainer>
      </Dialog>
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="klimaplan" element={<CommuneClimatePlanLayout />}>
          {klimaplanRoutes?.map((route, i) => (
            <Route key={"klimaplan_" + i} path={route.path} element={<route.element />} />
          ))}
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          {isAdmin && (
            <React.Fragment>
              {adminRoutes.map((route: RouteType, i: number) => (
                <Route
                  key={"admin_" + i}
                  path={route.path}
                  element={isAuthorized || route.allowAnonymous ? <route.element /> : <Navigate to="/" replace />}
                />
              ))}
              <Route path="" element={<Navigate to="dashboard" replace />} />
            </React.Fragment>
          )}
          {!isAdmin && <Route path="*" element={<Navigate to="/" replace />} />}
        </Route>
        <Route element={<BaseLayout />}>
          <React.Fragment>
            <Route path="*" element={<Navigate to="/" replace />} />
            {pagesRoutes.map((route: RouteType, i: number) => (
              <Route
                key={"base_" + i}
                path={route.path}
                element={isAuthorized || route.allowAnonymous ? <route.element /> : <Navigate to="/" replace />}
              />
            ))}
          </React.Fragment>
        </Route>
        <Route path="/buildings" element={<BuildingLayout />}>
          <React.Fragment>
            {buildingRoutes?.map((route, i) => (
              <Route
                key={"building_" + i}
                path={route.path}
                element={isAuthorized || route.allowAnonymous ? <route.element /> : <Navigate to="/" replace />}
              />
            ))}
          </React.Fragment>
        </Route>
        <Route path="/contractors" element={<ContractorLayout />}>
          <React.Fragment>
            {contractorRoutes?.map((route, i) => (
              <Route
                key={"contractor_" + i}
                path={route.path}
                element={isAuthorized || route.allowAnonymous ? <route.element /> : <Navigate to="/" replace />}
              />
            ))}
          </React.Fragment>
        </Route>
        <Route path="/" element={<PropertyLayout />}>
          <React.Fragment>
            {propertyRoutes?.map((route: RouteType, i: number) => (
              <Route
                key={"property_" + i}
                path={route.path}
                element={isAuthorized || route.allowAnonymous ? <route.element /> : <Navigate to="/" replace />}
              />
            ))}
          </React.Fragment>
        </Route>
        {/* TODO: remove this route when make changes to the system message */}
        <Route path="/:addressURL/building/:token/join" element={<OldInviteBuildingRedirect />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesMap;
