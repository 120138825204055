export const TenderActions = {
  TENDER_ACTION_FAILED: "TENDER_ACTION_FAILED",
  CLEAR_ERROR: "TENDER_CLEAR_ERROR",
  CREATE_TENDER: "CREATE_TENDER",
  CREATE_TENDER_SUCCEEDED: "CREATE_TENDER_SUCCEEDED",
  UPDATE_TENDER: "UPDATE_TENDER",
  UPDATE_TENDER_SUCCEEDED: "UPDATE_TENDER_SUCCEEDED",
  ARCHIVE_TENDER: "ARCHIVE_TENDER",
  ARCHIVE_TENDER_SUCCEEDED: "ARCHIVE_TENDER_SUCCEEDED",
  GET_TENDERS: "GET_TENDERS",
  GET_TENDERS_SUCCEEDED: "GET_TENDERS_SUCCEEDED",
  GET_CONTRACTOR_TENDERS: "GET_CONTRACTOR_TENDERS",
  GET_CONTRACTOR_TENDERS_SUCCEEDED: "GET_CONTRACTOR_TENDERS_SUCCEEDED",
  GET_CONTRACTOR_ARCHIVED_TENDERS: "GET_CONTRACTOR_ARCHIVED_TENDERS",
  GET_CONTRACTOR_ARCHIVED_TENDERS_SUCCEEDED: "GET_CONTRACTOR_ARCHIVED_TENDERS_SUCCEEDED",
  GET_ARCHIVED_TENDERS: "GET_ARCHIVED_TENDERS",
  GET_ARCHIVED_TENDERS_SUCCEEDED: "GET_ARCHIVED_TENDERS_SUCCEEDED",
  GET_TENDER: "GET_TENDER",
  GET_TENDER_SUCCEEDED: "GET_TENDER_SUCCEEDED",
  LINK_TENDER_CONTRACTOR: "LINK_TENDER_CONTRACTOR",
  LINK_TENDER_CONTRACTOR_SUCCEEDED: "LINK_TENDER_CONTRACTOR_SUCCEEDED",
  UNLINK_TENDER_CONTRACTOR: "UNLINK_TENDER_CONTRACTOR",
  UNLINK_TENDER_CONTRACTOR_SUCCEEDED: "UNLINK_TENDER_CONTRACTOR_SUCCEEDED",
  GET_TENDER_CONTRACTORS: "GET_TENDER_CONTRACTORS",
  GET_TENDER_CONTRACTORS_SUCCEEDED: "GET_TENDER_CONTRACTORS_SUCCEEDED",
  GET_CONTRACTOR_DETAILS: "GET_CONTRACTOR_DETAILS",
  GET_CONTRACTOR_DETAILS_SUCCEEDED: "GET_CONTRACTOR_DETAILS_SUCCEEDED",
  INVITE_CONTRACTOR_EMPLOYEE: "INVITE_CONTRACTOR_EMPLOYEE",
  INVITE_CONTRACTOR_EMPLOYEE_SUCCEEDED: "INVITE_CONTRACTOR_EMPLOYEE_SUCCEEDED",
  ACCEPT_INVITE_CONTRACTOR_MEMBER: "ACCEPT_INVITE_CONTRACTOR_MEMBER",
  ACCEPT_INVITE_CONTRACTOR_MEMBER_SUCCEEDED: "ACCEPT_INVITE_CONTRACTOR_MEMBER_SUCCEEDED",
};
