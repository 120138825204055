import { Dispatch } from "redux";
import { AppAction, AppActionThunk, AppThunkDispatch } from "../definitions/Action";
import { AdminLandingActionTypes } from "../constants/admin/admin.landing.actiontypes";
import { LandingActionTypes } from "../constants/landing.actiontypes";
import LandingService from "./../services/landing.service";
import { WidgetPage } from "../constants/enums";
import {
  WidgetMetaModel,
  WidgetCreateModel,
  LandingPageDto,
  LandingType,
  WidgetModel,
  WidgetsResponseModel,
} from "../definitions/Landing";
import { RegisterLeadContract } from "../definitions/LiteCapaign";
import User, { UserSettingsDTO } from "../definitions/model/User";
import { useSelector } from "react-redux";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { CO2Plan, SaveEmissionSource } from "../pages/ClimatePlan/shared/CO2Plan";
import Unit from "../definitions/model/unit/Unit";
import { ApplicationState } from "../reducers/store";
import { CompanyModel, CompanyTestimonialModel } from "../definitions/Company";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, AdminLandingActionTypes.LANDING_OPERATION_FAILED);
};

export const getUsersCount = () => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: LandingActionTypes.GET_USERS_COUNT,
    });
    const result = await LandingService.getCustomersCount();
    dispatch({
      type: LandingActionTypes.GET_USERS_COUNT_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export type UserSensitiveItems = {
  climatplan: CO2Plan | null;
  unit: Unit | null;
};

const getInnerKey = (keys: string[], object: any) => {
  let result: any = object;

  if (result) {
    for (const key of keys) {
      if (result[key]) {
        result = result[key];
      } else {
        return false;
      }
    }
  }
  return result;
};

export const getWidgetsByPage =
  (
    page: WidgetPage,
    skip: number,
    take: number,
    subPage = "",
    unitCommune: number | null = null,
    userSensitiveItems?: UserSensitiveItems,
    languageId?: string
  ) =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      const filterSesitiveWidgets = (widgets: WidgetModel[], userSensitiveItems: UserSensitiveItems) => {
        return widgets.filter((widget) => {
          if (widget.SensitiveList.length) {
            for (const sesitiveItem of widget.SensitiveList) {
              if (!sesitiveItem.Path.length) return true;
              const keys = sesitiveItem.Path.split(".");
              const essence = keys[0];
              const objectKeys = keys.slice(1);

              if (essence === "Climatplan") {
                const searchedField = getInnerKey(objectKeys, userSensitiveItems.climatplan);

                if (sesitiveItem.SensitiveAction === "false") {
                  return !searchedField;
                }

                if (sesitiveItem.SensitiveAction === "true") {
                  return !!searchedField;
                }

                if (typeof searchedField === "number") {
                  return eval(`${searchedField} ${sesitiveItem.SensitiveAction} ${sesitiveItem.SensitiveAmount}`);
                }

                return true;
              }

              if (essence === "Unit") {
                const searchedField = getInnerKey(objectKeys, userSensitiveItems.unit);

                if (sesitiveItem.SensitiveAction === "false") {
                  return !searchedField;
                }

                if (sesitiveItem.SensitiveAction === "true") {
                  return !!searchedField;
                }

                if (typeof searchedField === "number") {
                  return eval(`${sesitiveItem.SensitiveAmount} ${sesitiveItem.SensitiveAction} ${searchedField}`);
                }

                return true;
              }

              return true;
            }
          }

          return true;
        });
      };
      dispatch({ type: LandingActionTypes.GET_WIDGETS });
      const result = await LandingService.getWidgetsByPage(page, skip, take, subPage, unitCommune, languageId);

      const results: WidgetsResponseModel = userSensitiveItems
        ? {
            Results: filterSesitiveWidgets(result.Results, userSensitiveItems),
            Count: filterSesitiveWidgets(result.Results, userSensitiveItems).length,
          }
        : {
            Results: result.Results,
            Count: result.Count,
          };

      dispatch({
        type:
          skip === 0 ? LandingActionTypes.GET_WIDGETS_SUCCEEDED : LandingActionTypes.GET_WIDGETS_ADDITIONAL_SUCCEEDED,
        payload: results,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getWidget =
  (widgetId: string): AppActionThunk<Promise<any>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminLandingActionTypes.GET_WIDGET });
      const result = await LandingService.getWidget(widgetId);
      dispatch({ type: AdminLandingActionTypes.GET_WIDGET_SUCCEEDED });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getWidgets =
  (searchString: string, skip: number, take: number) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminLandingActionTypes.GET_WIDGETS });
      const result = await LandingService.getWidgets(searchString, skip, take);
      dispatch({
        type: AdminLandingActionTypes.GET_WIDGETS_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const addWidget = (widget: WidgetCreateModel) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminLandingActionTypes.ADD_WIDGET });
    const result = await LandingService.addWidget(widget);
    dispatch({
      type: AdminLandingActionTypes.ADD_WIDGET_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getEditor =
  (editorId: string): AppActionThunk<Promise<any>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminLandingActionTypes.GET_EDITOR });
      const result = await LandingService.getEditor(editorId);
      dispatch({ type: AdminLandingActionTypes.GET_EDITOR_SUCCEEDED });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const updateWidget =
  (widget: WidgetMetaModel): AppActionThunk<Promise<any>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminLandingActionTypes.UPDATE_WIDGET });
      const result = await LandingService.updateWidget(widget);
      dispatch({
        type: AdminLandingActionTypes.UPDATE_WIDGET_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const deleteWidget = (widgetId: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminLandingActionTypes.REMOVE_WIDGET });
    await LandingService.deleteWidget(widgetId);
    dispatch({
      type: AdminLandingActionTypes.REMOVE_WIDGET_SUCCEEDED,
      payload: widgetId,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const cleanWidgets = () => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminLandingActionTypes.RESET_WIDGETS });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const clearError = () => operationFailedAction("");

export const setSearchString = (searchString: string, take: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminLandingActionTypes.SET_SEARCH_STRING,
      payload: searchString,
    });
    dispatch({ type: AdminLandingActionTypes.GET_WIDGETS });
    const result = await LandingService.getWidgets(searchString, 0, take);
    dispatch({ type: AdminLandingActionTypes.RESET_WIDGETS });
    dispatch({
      type: AdminLandingActionTypes.GET_WIDGETS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const resetWidgets = () => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminLandingActionTypes.RESET_WIDGETS });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const addLead = (data: RegisterLeadContract) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminLandingActionTypes.ADD_LEAD });
    await LandingService.addLead(data);
    dispatch({ type: AdminLandingActionTypes.ADD_LEAD_SUCCEEDED });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getLandingPage =
  (uniqueName: string, languageId?: string) =>
  async (dispatch: AppThunkDispatch): Promise<LandingPageDto> => {
    try {
      dispatch({ type: LandingActionTypes.GET_LANDING });
      const result = await LandingService.getLandingPage(uniqueName, languageId);
      dispatch({
        type: LandingActionTypes.GET_LANDING_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getUserByUniqueName =
  (uniqueName: string) =>
  async (dispatch: AppThunkDispatch): Promise<User> => {
    try {
      dispatch({ type: LandingActionTypes.GET_USER });
      const result = await LandingService.getUserByUniqueName(uniqueName);
      dispatch({
        type: LandingActionTypes.GET_USER_SUCCEEDED,
        payload: { User: result },
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const activateUser =
  (userId: string) =>
  async (dispatch: AppThunkDispatch): Promise<User> => {
    try {
      dispatch({ type: LandingActionTypes.ACTIVATE_USER });
      const result = await LandingService.activateUser(userId);
      dispatch({
        type: LandingActionTypes.ACTIVATE_USER_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getLandingUserSettings =
  (userId: string | null = null): AppActionThunk<Promise<UserSettingsDTO | undefined>> =>
  async (dispatch) => {
    dispatch({ type: LandingActionTypes.GET_USER_SETTIINGS });
    try {
      const result = await LandingService.getLandingUserSettings(userId);
      dispatch({
        type: LandingActionTypes.GET_USER_SETTIINGS_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const updateLandingUserSettigns =
  (
    userId: string | null,
    sendNotifications: boolean,
    receiveCalls: boolean,
    sendFacebookNotifications: boolean
  ): AppActionThunk<Promise<any>> =>
  async (dispatch) => {
    dispatch({ type: LandingActionTypes.UPDATE_USER_SETTIINGS });
    try {
      if (userId !== null) {
        const result = await LandingService.updateLandingUserSettigns(
          userId,
          sendNotifications,
          receiveCalls,
          sendFacebookNotifications
        );
        dispatch({
          type: LandingActionTypes.UPDATE_USER_SETTIINGS_SUCCEEDED,
          payload: result,
        });
        return result;
      }
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getLatestTerms =
  (languageId: string) =>
  async (dispatch: AppThunkDispatch): Promise<LandingPageDto> => {
    try {
      const result = await LandingService.getLatestTerms(languageId);
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getClientCompanies =
  () =>
  async (dispatch: AppThunkDispatch): Promise<CompanyModel[]> => {
    try {
      const result = await LandingService.getClientCompanies();
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getCompanyTestimonials =
  () =>
  async (dispatch: AppThunkDispatch): Promise<CompanyTestimonialModel[]> => {
    try {
      const result = await LandingService.getCompanyTestimonials();
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getCO2Meta = (metaId: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: LandingActionTypes.GET_CO2_META });
    const result = await LandingService.getCO2Meta(metaId);
    dispatch({
      type: LandingActionTypes.GET_CO2_META_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const saveCO2MetaAnswers =
  (planId: string, email: string | null, customQuestions: SaveEmissionSource[] | null) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: LandingActionTypes.SAVE_CO2_META_ANSWERS });
      await LandingService.saveCO2MetaAnswers(planId, email, customQuestions);
      dispatch({ type: LandingActionTypes.SAVE_CO2_META_ANSWERS_SUCCEEDED });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const useLandingActions = () => {
  const dispatch = useAppDispatch();
  return {
    addLead: (data: RegisterLeadContract) => dispatch(addLead(data)),
    getUserByEmail: (email: string) => dispatch(getUserByUniqueName(email)),
    getWidgetsByPage: (
      page: WidgetPage,
      skip: number,
      take: number,
      subPage = "",
      unitCommune: number | null,
      userSensitiveItems?: UserSensitiveItems,
      languageId?: string
    ) => dispatch(getWidgetsByPage(page, skip, take, subPage, unitCommune, userSensitiveItems, languageId)),
    getLatestTerms: (languageId: string) => dispatch(getLatestTerms(languageId)),
    getLandingPage: (uniqueName: string, languageId: string) => dispatch(getLandingPage(uniqueName, languageId)),
    getClientCompanies: () => dispatch(getClientCompanies()),
    getCompanyTestimonials: () => dispatch(getCompanyTestimonials()),
    getCO2Meta: (metaId: string) => dispatch(getCO2Meta(metaId)),
    saveCO2MetaAnswers: (planId: string, email: string | null, customQuestions: SaveEmissionSource[] | null) =>
      dispatch(saveCO2MetaAnswers(planId, email, customQuestions)),
  };
};

export const useLandingState = () => useSelector((state: ApplicationState) => state.landing);

export const useLanding = (): [ReturnType<typeof useLandingState>, ReturnType<typeof useLandingActions>] => {
  const state = useLandingState();
  const actions = useLandingActions();

  return [state, actions];
};
