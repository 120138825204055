import { Reducer } from "react";
import { UserConstants, FamilyConstatns, BuildingActionTypes } from "../constants";
import User, { UserRating, UserSettingsDTO } from "../definitions/model/User";
import { AppAction } from "../definitions/Action";
import { UserSettingsHistoryModel } from "../definitions/model/UserSettings";
import { LanguageType } from "../definitions/Menu";

export interface UserStoreState {
  uiMode: "unit" | "building" | "contractor";
  loading: boolean;
  user: User | null;
  mainUser: User | null;
  language: LanguageType | undefined;
  error: string | null;
  hubConnection: signalR.HubConnection | null;
  settings: UserSettingsDTO | null;
  history: UserSettingsHistoryModel[];
  openCreateDialog: boolean;
  openLoginDialog: boolean;
  authSimpleRegistration: boolean;
  authDestination: string;
  authRegisterDestination: string;
  authUserRating: UserRating;
  loginAndStay: boolean;
  loginOnly: boolean;
  loginButtonText?: string;
  employerId?: string;
  authPropertyId?: string;
  communeNumber?: number;
  showMoveInDialog: boolean;
  onRegistrationComplete: any;
  pwaOptions: any;
  themeName: string | null;
  isMaintenance: boolean;
  needToAcceptTerms: boolean;
  wasTheUserAuthorized: boolean;
}

export const initialState: UserStoreState = {
  uiMode: "unit",
  user: null,
  mainUser: null,
  loading: false,
  error: null,
  hubConnection: null,
  settings: null,
  history: [],
  openCreateDialog: false,
  openLoginDialog: false,
  authSimpleRegistration: false,
  authDestination: "",
  authRegisterDestination: "",
  authUserRating: 1,
  showMoveInDialog: false,
  onRegistrationComplete: null,
  pwaOptions: null,
  themeName: null,
  isMaintenance: false,
  needToAcceptTerms: false,
  wasTheUserAuthorized: false,
  loginAndStay: false,
  loginOnly: false,
  language: undefined,
};

const userReducer: Reducer<UserStoreState, AppAction> = (
  state: UserStoreState = initialState,
  action: AppAction
): UserStoreState => {
  switch (action.type) {
    case UserConstants.MOVE_IN_UNIT:
    case UserConstants.COMPLETE_REGISTRATION:
    case UserConstants.REMOVE_USER:
    case UserConstants.UPDATE_USER_INTERESTS:
    case UserConstants.GET_USER_SETTIINGS:
    case UserConstants.UPDATE_USER_PROFILE:
    case UserConstants.UPDATE_USER_PICTURE:
    case UserConstants.MOVE_OUT_UNIT:
    case FamilyConstatns.SEND_FAMILY_JOIN_REQUEST:
    case UserConstants.TRY_USER:
      return {
        ...state,
        loading: true,
      };
    case UserConstants.MOVE_IN_UNIT_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: null,
        loading: false,
      };
    case BuildingActionTypes.MOVE_IN_BUILDING_SUCCESS:
      return {
        ...state,
        user: action.payload.User,
        error: null,
        loading: false,
      };
    case UserConstants.COMPLETE_REGISTRATION_SUCCESS:
    case UserConstants.LOGIN_SUCCESS:
    case UserConstants.REGISTER_SUCCESS:
    case UserConstants.GET_USER_SUCCEEDED:
      return {
        ...state,
        user: action.payload.User,
        mainUser: action.payload.MainUser,
        error: null,
        loading: false,
        wasTheUserAuthorized: false,
        language: action.payload.User.language,
      };
    case UserConstants.SET_IS_MAINTENANCE:
      return {
        ...state,
        isMaintenance: true,
      };
    case UserConstants.LOGOUT:
      return {
        ...state,
        user: null,
        loading: false,
        wasTheUserAuthorized: true,
      };
    case UserConstants.SET_USERNAME:
      if (state.user) state.user.Name = action.payload.name;
      return {
        ...state,
        loading: false,
      };
    case UserConstants.USER_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload && action.payload.statusText !== undefined ? action.payload.statusText : action.payload,
        loading: false,
      };
    case UserConstants.INIT_SIGNALR_SUCCEEDED:
      return {
        ...state,
        hubConnection: action.payload,
      };
    case UserConstants.STOP_SIGNALR_SUCCEEDED:
      return {
        ...state,
        hubConnection: null,
      };
    case UserConstants.UPDATE_USER_INTERESTS_SUCCEEDED:
      return {
        ...state,
        user: state.user ? { ...state.user, UserInterest: action.payload } : null,
        loading: false,
      };
    case UserConstants.REMOVE_USER_SUCCEEDED:
      return {
        ...state,
        user: null,
        loading: false,
      };
    case UserConstants.GET_USER_SETTIINGS_SUCCEEDED:
      return {
        ...state,
        settings: action.payload,
        loading: false,
      };
    case UserConstants.UPDATE_USER_PROFILE_SUCCEEDED:
    case UserConstants.UPDATE_USER_LANGUAGE_SUCCEEDED:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        loading: false,
        language: action.payload.Language,
      };
    case UserConstants.GET_USER_SETTINGS_HISTORY_SUCCEEDED: {
      return {
        ...state,
        history: action.payload,
        loading: false,
      };
    }
    case UserConstants.UPDATE_USER_NOTIFICATION_SETTINGS_SUCCEEDED:
      return {
        ...state,
        settings: state.settings
          ? {
              ...state.settings,
              ReceiveNotifications: action.payload.ReceiveNotifications,
              ReceiveCalls: action.payload.ReceiveCalls,
              ReceiveFacebookNotifications: action.payload.ReceiveFacebookNotifications,
            }
          : null,
        loading: false,
      };
    case UserConstants.UPDATE_USER_PICTURE_SUCCEEDED:
      return {
        ...state,
        user: state.user ? { ...state.user, AvatarUrl: action.payload } : null,
        loading: false,
      };
    case UserConstants.MOVE_OUT_UNIT_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loading: false,
      };
    case UserConstants.SET_AUTH_DESTINATION:
      return {
        ...state,
        authSimpleRegistration: action.payload?.authSimpleRegistration || false,
        authDestination: action.payload?.authDestination || "",
        authRegisterDestination: action.payload?.registerExtraStep || "",
        authUserRating: action.payload?.authUserRating ?? state.authUserRating,
      };
    case UserConstants.OPEN_CREATE_DIALOG:
      return {
        ...state,
        openCreateDialog: true,
        authPropertyId: action.payload.authPropertyId ?? undefined,
        employerId: action.payload.employerId ?? undefined,
        communeNumber: action.payload.communeNumber ?? undefined,
      };
    case UserConstants.OPEN_CREATE_DIALOG_SUCCEEDED:
      return {
        ...state,
        openCreateDialog: false,
      };
    case UserConstants.OPEN_LOGIN_DIALOG:
      return {
        ...state,
        openLoginDialog: true,
        loginAndStay: !!action.payload.loginAndStay,
        loginOnly: !!action.payload.loginOnly,
        loginButtonText: action.payload.loginButtonText ?? undefined,
        employerId: action.payload.employerId ?? undefined,
        communeNumber: action.payload.communeNumber ?? undefined,
      };
    case UserConstants.OPEN_LOGIN_DIALOG_SUCCEEDED:
      return {
        ...state,
        openLoginDialog: false,
      };
    case UserConstants.SHOW_MOVE_IN_DIALOG:
      return {
        ...state,
        showMoveInDialog: true,
      };
    case UserConstants.HIDE_MOVE_IN_DIALOG:
      return {
        ...state,
        showMoveInDialog: false,
      };
    case UserConstants.SET_REGISTRATION_COMPLETE:
      return {
        ...state,
        onRegistrationComplete: action.payload,
      };
    case FamilyConstatns.SEND_FAMILY_JOIN_REQUEST_SUCCEEDED:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case UserConstants.SET_PWA_OPTIONS:
      return {
        ...state,
        pwaOptions: action.payload,
      };
    case UserConstants.GET_USER_THEME_SUCCEEDED:
      return {
        ...state,
        themeName: action.payload,
      };
    case UserConstants.CHANGE_USER_CURRENT_HOUSE_SUCCEEDED:
      return {
        ...state,
        user: action.payload.User,
      };
    case UserConstants.ACCEPT_NEW_TERMS:
      return {
        ...state,
        needToAcceptTerms: true,
      };
    case UserConstants.NEW_TERMS_ACCEPTED:
      return {
        ...state,
        needToAcceptTerms: false,
      };
    case UserConstants.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case UserConstants.TRY_USER_SUCCEEDED:
      return {
        ...state,
        error: null,
        loading: false,
        mainUser: state.user,
        user: action.payload.User,
      };
    case UserConstants.UNTRY_USER_SUCCEEDED:
      return {
        ...state,
        error: null,
        loading: false,
        user: state.mainUser,
        mainUser: null,
      };
    case UserConstants.SET_UI_MODE:
      return {
        ...state,
        uiMode: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
