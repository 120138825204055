import { AnyAction } from "redux";
import { TenderService } from "../services/tender.service";
import { TenderActions } from "../constants/tender.actiontypes";
import { CreateTenderRequest, UpdateTenderRequest } from "../definitions/model/ServiceBook";
import { AppAction } from "../definitions/Action";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers/store";
import { ThunkDispatch } from "redux-thunk";
import { operationFailedActionGeneral, useAppDispatch } from ".";

const useTenderState = () => useSelector((state: ApplicationState) => state.tender);

const useTenderActions = () => {
  const dispatch = useAppDispatch();
  return {
    clearError: () => dispatch(clearError()),
    createTender: (request: CreateTenderRequest) => dispatch(createTender(request)),
    updateTender: (request: UpdateTenderRequest) => dispatch(updateTender(request)),
    archiveTender: (tenderId: string) => dispatch(archiveTender(tenderId)),
    getTenders: (propertyId: string) => dispatch(getTenders(propertyId)),
    getArchivedTenders: (propertyId: string) => dispatch(getArchivedTenders(propertyId)),
    getContractorTenders: (contractorId: string) => dispatch(getContractorTenders(contractorId)),
    getContractorArchivedTenders: (contractorId: string) => dispatch(getContractorArchivedTenders(contractorId)),
    getTenderDetails: (tenderId: string) => dispatch(getTenderDetails(tenderId)),
    linkTenderContractor: (tenderId: string, contractorId: string) =>
      dispatch(linkTenderContractor(tenderId, contractorId)),
    unlinkTenderContractor: (tenderId: string, contractorId: string) =>
      dispatch(unlinkTenderContractor(tenderId, contractorId)),
    getTenderContractors: (tenderId: string) => dispatch(getTenderContractors(tenderId)),
    getContractorDetails: (contractorId: string) => dispatch(getContractorDetails(contractorId)),
    inviteContractorEmployee: (contractorId: string, name: string, email: string) =>
      dispatch(inviteContractorEmployee(contractorId, name, email)),
    acceptInviteContractorMember: (contractorId: string, token: string) => dispatch(acceptInviteContractorMember(contractorId, token)),
  };
};

export const useTender = (): [ReturnType<typeof useTenderState>, ReturnType<typeof useTenderActions>] => {
  const state = useTenderState();
  const actions = useTenderActions();
  return [state, actions];
};

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, TenderActions.TENDER_ACTION_FAILED);
};

const clearError = () => (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  dispatch({ type: TenderActions.CLEAR_ERROR });
};

const createTender =
  (request: CreateTenderRequest) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({ type: TenderActions.CREATE_TENDER });
      const result = await TenderService.createTender(request);
      if (result && Object.keys(result).length) {
        dispatch({
          type: TenderActions.CREATE_TENDER_SUCCEEDED,
          payload: result,
        });
      }
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const updateTender =
  (request: UpdateTenderRequest) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({ type: TenderActions.UPDATE_TENDER });
      const result = await TenderService.updateTender(request);
      if (result && Object.keys(result).length) {
        dispatch({
          type: TenderActions.UPDATE_TENDER_SUCCEEDED,
          payload: result,
        });
      }
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const archiveTender = (tenderId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({ type: TenderActions.ARCHIVE_TENDER });
    const result = await TenderService.archiveTender(tenderId);
    if (result && Object.keys(result).length) {
      dispatch({
        type: TenderActions.ARCHIVE_TENDER_SUCCEEDED,
        payload: result,
      });
    }
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getTenders = (propertyId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({ type: TenderActions.GET_TENDERS });
    const result = await TenderService.getTenders(propertyId);
    dispatch({
      type: TenderActions.GET_TENDERS_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getContractorTenders = (contractorId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({ type: TenderActions.GET_CONTRACTOR_TENDERS });
    const result = await TenderService.getContractorTenders(contractorId, false);
    dispatch({
      type: TenderActions.GET_CONTRACTOR_TENDERS_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
}

const getContractorArchivedTenders = (contractorId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({ type: TenderActions.GET_CONTRACTOR_ARCHIVED_TENDERS });
    const result = await TenderService.getContractorTenders(contractorId, true);
    dispatch({
      type: TenderActions.GET_CONTRACTOR_ARCHIVED_TENDERS_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
}

const getArchivedTenders =
  (propertyId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({ type: TenderActions.GET_ARCHIVED_TENDERS });
      const result = await TenderService.getArchivedTenders(propertyId);
      dispatch({
        type: TenderActions.GET_ARCHIVED_TENDERS_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const getTenderDetails = (tenderId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({ type: TenderActions.GET_TENDER });
    const result = await TenderService.getTenderDetails(tenderId);
    dispatch({
      type: TenderActions.GET_TENDER_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const linkTenderContractor =
  (tenderId: string, contractorId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({ type: TenderActions.LINK_TENDER_CONTRACTOR });
      const result = await TenderService.linkTenderContractor(tenderId, contractorId);
      dispatch({
        type: TenderActions.LINK_TENDER_CONTRACTOR_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const unlinkTenderContractor =
  (tenderId: string, contractorId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({ type: TenderActions.UNLINK_TENDER_CONTRACTOR });
      const result = await TenderService.unlinkTenderContractor(tenderId, contractorId);
      dispatch({
        type: TenderActions.UNLINK_TENDER_CONTRACTOR_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const getTenderContractors =
  (tenderId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({ type: TenderActions.GET_TENDER_CONTRACTORS });
      const result = await TenderService.getTenderContractors(tenderId);
      dispatch({
        type: TenderActions.GET_TENDER_CONTRACTORS_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const getContractorDetails = (contractorId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({ type: TenderActions.GET_CONTRACTOR_DETAILS });
    const result = await TenderService.getContractorDetails(contractorId);
    dispatch({
      type: TenderActions.GET_CONTRACTOR_DETAILS_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const inviteContractorEmployee =
  (contractorId: string, name: string, email: string) =>
    async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
      try {
        dispatch({ type: TenderActions.INVITE_CONTRACTOR_EMPLOYEE });
        const result = await TenderService.inviteContractorEmployee(contractorId, name, email);
        dispatch({
          type: TenderActions.INVITE_CONTRACTOR_EMPLOYEE_SUCCEEDED,
          payload: result,
        });

        return result;
      } catch (error) {
        dispatch(operationFailedAction(error));
      }
    };

const acceptInviteContractorMember = (contractorId: string, token: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({ type: TenderActions.ACCEPT_INVITE_CONTRACTOR_MEMBER });
    const result = await TenderService.acceptInviteContractorMember(contractorId, token);
    dispatch({
      type: TenderActions.ACCEPT_INVITE_CONTRACTOR_MEMBER_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
}
