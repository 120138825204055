import React from "react";
import { useLocation, useNavigate, matchPath } from "react-router-dom";
import { Grid, Link, useMediaQuery, Typography, GridProps, LinkProps, useTheme } from "@mui/material";
import { menuClick } from "../../pages/Overview/Components/MenuBlockList";
import { styled } from "@mui/material/styles";
import { useUser } from "../../actions/user.actions";
import { GetTranslatedText } from "../../helpers/translation-helper";
import { NavigationItem } from "../../definitions/Menu";
import { useUnit } from "../../actions/unit.actions";
import { useBuilding } from "../../actions/building.actions";
import NavigationHelper from "../../helpers/navigation-helper";
import { buildingRoutes } from "../../pages/Building/BuildingLayout";
import { pagesRoutes } from "./BaseLayout";
import { propertyRoutes } from "./PropertyLayout";
import { contractorRoutes } from "../../pages/Contractor/ContractorLayout";
import { useTender } from "../../actions/tender.actions";

interface MenuWrapperProps extends GridProps {
  menuColor?: string;
}

interface MenuLinkProps extends LinkProps {
  isSafari: boolean;
  menuColor?: string;
}

const MenuWrapper = styled(Grid, {
  shouldForwardProp: (propName) => propName !== "menuColor",
})<MenuWrapperProps>(({ theme, menuColor }) => ({
  backgroundColor: menuColor ? menuColor : theme.palette.background.grayPaper,
  margin: "20px 0",
}));

const MenuLink = styled(Link, {
  shouldForwardProp: (propName) => propName !== "isSafari" && propName !== "menuColor",
})<MenuLinkProps>(({ theme, isSafari, menuColor }) => ({
  color: menuColor ? theme.palette.getContrastText(menuColor) : "#000",
  minHeight: 48,
  padding: 10,
  boxSizing: "border-box",
  display: "inline-flex",
  alignItems: "center",
  "&:hover": {
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: 600,
    letterSpacing: isSafari ? -0.8 : 0,
  },
}));

type MenuType = {
  menuColor?: string;
  preview?: boolean;
  menuItems: NavigationItem[] | null;
};

const MainMenu = (props: MenuType): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [{ unit }] = useUnit();
  const [{ user, uiMode }] = useUser();
  const [{ building }] = useBuilding();
  const [{ contractor }] = useTender();

  const allRoutes = [
    ...buildingRoutes.map((b) => ({ ...b, path: "/buildings/" + b.path })),
    ...pagesRoutes,
    ...propertyRoutes.map((p) => ({ ...p, path: "/" + p.path })),
    ...contractorRoutes.map((c) => ({ ...c, path: "/contractors/" + c.path })),
  ];
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const isActiveRoute = (route: string) => {
    const currentRoute = allRoutes.find((r) => {
      if (r.parentPath && matchPath({ path: r.parentPath }, pathname)) return true;
      if (matchPath({ path: r.path }, pathname)) return true;

      return false;
    });
    if (!currentRoute) return false;

    const pathRoute = NavigationHelper.GetPathRoute(route);
    if (currentRoute.parentPath && matchPath({ path: currentRoute.parentPath }, pathRoute)) return true;
    if (matchPath({ path: currentRoute.path }, pathRoute)) return true;

    return false;
  };

  return !isXs ? (
    <MenuWrapper menuColor={props.menuColor} container>
      <Grid container alignItems="flex-start" justifyContent="center">
        {props.menuItems &&
          props.menuItems.map((item: NavigationItem) =>
            item.ShowInTopBar &&
            item.IsPublished &&
            (!item.UserSensitive || (item.UserSensitive && user && item.Rating <= user.Rating)) ? (
              <MenuLink
                key={item.TargetUrl}
                sx={{
                  backgroundColor: isActiveRoute(item.TargetUrl) ? theme.palette.grey[400] : "inherit",
                }}
                isSafari={isSafari}
                menuColor={props.menuColor}
                onClick={() => {
                  if (props.preview) return;

                  const isMatch = matchPath({ path: NavigationHelper.GetPathRoute(item.TargetUrl) }, pathname);
                  console.log("isMatch: ", isMatch);
                  menuClick(item.TargetUrl, item.UnitSensitive, unit, building, contractor, navigate, uiMode);
                }}
              >
                <Typography variant="body1">{GetTranslatedText(item.Titles)}</Typography>
              </MenuLink>
            ) : null
          )}
      </Grid>
    </MenuWrapper>
  ) : (
    <></>
  );
};

export default MainMenu;
