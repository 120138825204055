import React from "react";
import { Outlet } from "react-router-dom";
import {
  Widgets,
  SupervisorAccount,
  Map,
  MenuBook,
  PersonAdd,
  ErrorOutline,
  Web,
  DeveloperBoard,
  DynamicFeed,
  Group,
  CardMembership,
  Comment,
  EnergySavingsLeaf,
  Business,
  Book,
  ViewModule,
  OfflineBolt,
  Message,
  Info,
  Engineering,
  ManageAccounts,
  Settings,
} from "@mui/icons-material";
import { MenuItemModel } from "./components/MenuItem";
import User from "../../definitions/model/User";
import { UserType } from "../../constants/enums";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { useUser } from "../../actions/user.actions";
import { RouteType } from "../../routes";

const Layout = React.lazy(() => import("./AdminPanelBasicLayout"));
// import NeighborGroupsPage from "./pages/NeighborGroups/NeighborGroupsPage";
// import EditNeighborGroupPage from "./pages/NeighborGroups/EditNeighborGroupPage";
{
  /* <Route path="neighbor-groups/:id"} element={<EditNeighborGroupPage/>} />
              <Route path="neighbor-groups"} element={<NeighborGroupsPage/>} /> */
}

export const adminRoutes: RouteType[] = (() => {
  return [
    {
      title: "",
      path: "administrators",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Administrators/Administrators")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "users",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Users/UsersPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "widgets",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Widgets/Widgets")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "widgets/:id",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Widgets/WidgetEditor")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "clubs/:id",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Clubs/ClubPageEditor")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "clubs/:id/article/:articleId",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Clubs/ArticleEditorPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "products/:id",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Products/ProductEditor")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "landings/:id/:langId?",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Landing/EditLandingPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "templates/:id",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Templates/EditTemplatePage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "news/:id",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/News/EditNewsPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "benefitclubs/:id",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/BenefitClubs/EditBenefitClubPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "terms-editor/:id",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Terms/EditTermsPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "articleGroups",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Clubs/ClubsPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "companies",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Companies/CompaniesPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "lead-alarms",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Leads/LeadsPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "landings",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Landing/LandingsPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "map",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Map/MapPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "news",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/News/NewsPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "benefitclubs",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/BenefitClubs/BenefitClubsPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "templates",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Templates/TemplatesPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "products",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Products/ProductsPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "dashboard",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Dashboard/DashboardPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "comments",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/CommentsModeration/CommentsModerationPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "co2",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/CO2Plans/CO2PlansPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "navigation",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/OverviewNavigation/OverviewNavigationPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "behaviourrules",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/BehaviourRules/BehaviourRulesPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "aggregate-view",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/AggregateViewPage/AggregateViewPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "green-craftsmen",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Craftsmen/CraftsmenPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "terms-editor",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/Terms/Terms")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "price-predictions",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/PricePredictions/PricePredictionsPage")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "system-messages/:id",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/SystemMessage/SystemMessageEditor")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "system-settings",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/SystemSettings")),
      allowAnonymous: false,
    },
    {
      title: "",
      path: "system-messages",
      element: React.lazy(() => import("../../pages/AdminPanel/pages/SystemMessage/SystemMessages")),
      allowAnonymous: false,
    },
  ];
})();

export interface AdminStateProps {
  user: User;
}

const AdminLayout = (): JSX.Element => {
  const [{ user }] = useUser();
  const pathname = "/admin";
  const listItems: MenuItemModel[] = [
    {
      icon: <ErrorOutline />,
      text: "Dashboard",
      href: pathname + "/dashboard",
    },
  ];

  if (user && user.Role >= UserType.Manager) {
    listItems.push(
      {
        icon: <Widgets />,
        text: "Widgets",
        href: pathname + "/widgets",
      },
      {
        icon: <Group />,
        text: "Articles groups",
        href: pathname + "/articleGroups",
      },
      {
        icon: <Business />,
        text: "Companies",
        href: pathname + "/companies",
      },
      {
        icon: <PersonAdd />,
        text: "Lead alarms",
        href: pathname + "/lead-alarms",
      },
      // {
      //   icon: <Build />,
      //   text: "Craftmens Campaign",
      //   href: pathname + "/craftsmens",
      // },
      {
        icon: <Web />,
        text: "Landings",
        href: pathname + "/landings",
      },
      {
        icon: <Map />,
        text: "Map",
        href: pathname + "/map",
      },
      {
        icon: <MenuBook />,
        text: "News",
        href: pathname + "/news",
      },
      {
        icon: <DynamicFeed />,
        text: "Fordelsklubber",
        href: pathname + "/benefitclubs",
      },
      {
        icon: <DeveloperBoard />,
        text: "Templates",
        href: pathname + "/templates",
      },
      {
        icon: <CardMembership />,
        text: "Products",
        href: pathname + "/products",
      },
      {
        icon: <Comment />,
        text: "Comments",
        href: pathname + "/comments",
      },
      {
        icon: <EnergySavingsLeaf />,
        text: "CO2 Plans",
        href: pathname + "/co2",
      },
      {
        icon: <ViewModule />,
        text: "Navigation",
        href: pathname + "/navigation",
      },
      {
        icon: <Book />,
        text: "Behaviour rules",
        href: pathname + "/behaviourrules",
      },
      {
        icon: <OfflineBolt />,
        text: "Price Predictions",
        href: pathname + "/price-predictions",
      },
      {
        icon: <Message />,
        text: "System Messages",
        href: pathname + "/system-messages",
      },
      {
        icon: <Settings />,
        text: "System Settings",
        href: pathname + "/system-settings",
      },
      {
        icon: <Info />,
        text: "Aggregate view",
        href: pathname + "/aggregate-view",
      },
      {
        icon: <Engineering />,
        text: "Craftsmen",
        href: pathname + "/green-craftsmen",
      },
      {
        icon: <Engineering />,
        text: "Data Policy Editor",
        href: pathname + "/terms-editor",
      }
      // {
      //   icon: <Diversity3 />,
      //   text: "Global neighborgroups",
      //   href: pathname + "/neighbor-groups",
      // }
    );
  }

  if (user && user.Role === UserType.ExternalManager) {
    listItems.push({
      icon: <EnergySavingsLeaf />,
      text: "CO2 Plans",
      href: pathname + "/co2",
    });
  }

  if (user && user.Role === UserType.Administrator) {
    listItems.push({
      icon: <SupervisorAccount />,
      text: "Administrators",
      href: pathname + "/administrators",
    });
    listItems.push({
      icon: <ManageAccounts />,
      text: "Users",
      href: pathname + "/users",
    });
  }

  return (
    <Layout itemsList={listItems} user={user}>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </Layout>
  );
};

export default AdminLayout;
