import ApiService from "./api.service";
import { CreateTenderRequest, Tender, UpdateTenderRequest } from "../definitions/model/ServiceBook";
import { SearchResult } from "../definitions/model/SearchResult";
import { Contractor, WorkerModel } from "../definitions/model/Worker";
import { GroupMemberRole } from "../constants/enums";

export const TenderService = {
  createTender: async (request: CreateTenderRequest) => {
    return ApiService.post<Tender>("/api/tenders", request);
  },
  updateTender: async (request: UpdateTenderRequest) => {
    return ApiService.put<Tender>("/api/tenders/" + request.TenderId, request);
  },
  archiveTender: async (tenderId: string) => {
    return ApiService.delete<Tender>(`/api/tenders/${tenderId}/archive`);
  },
  getTenders: async (propertyId: string) => {
    return ApiService.get<SearchResult<Tender>>("/api/tenders?propertyId=" + propertyId);
  },
  getContractorTenders: async (contractorId: string, archived: boolean) => {
    return ApiService.get<SearchResult<Tender>>(`/api/tenders/contractors/${contractorId}/tenders?archived=${archived}`);
  },
  getArchivedTenders: async (propertyId: string) => {
    return ApiService.get<SearchResult<Tender>>("/api/tenders/archive?propertyId=" + propertyId);
  },
  getTenderDetails: async (tenderId: string) => {
    return ApiService.get<Tender>("/api/tenders/" + tenderId);
  },
  linkTenderContractor: async (tenderId: string, contractorId: string) => {
    return ApiService.post<Tender>(`/api/tenders/${tenderId}/contractors/${contractorId}`, null);
  },
  unlinkTenderContractor: async (tenderId: string, contractorId: string) => {
    return ApiService.delete<Tender>(`/api/tenders/${tenderId}/contractors/${contractorId}`, null);
  },
  getTenderContractors: async (tenderId: string) => {
    return ApiService.get<WorkerModel[]>(`/api/tenders/${tenderId}/contractors`);
  },
  getContractorDetails: async (contractorId: string) => {
    return ApiService.get<Contractor>(`/api/tenders/contractors/${contractorId}`);
  },
  inviteContractorEmployee: async (contractorId: string, name: string, email: string) => {
    const data = {
      Name: name,
      Email: email,
      Role: GroupMemberRole.Member,
      Source: contractorId,
    };
    return ApiService.post<Contractor>(`/api/tenders/contractors/${contractorId}/invite`, data);
  },
  acceptInviteContractorMember: async (contractorId: string, token: string) => {
    const url = `/api/tenders/contractors/${contractorId}/members/${token}`;
    return await ApiService.put(url, null);
  },
};
