import React, { useEffect } from "react";
import {Chip, TextFieldProps, TextField, Autocomplete, AutocompleteRenderInputParams } from "@mui/material";
import { WidgetPage } from "../../constants/enums";

export type TransferListItem = {
  name: string;
  pageValue: WidgetPage | string;
  subPageValue: string;
};

type TransferListProps<T> = {
  options: Array<T>;
  selected: Array<T>;
  fieldLabel: string;
  placeholder: string;
  disabled?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  onChanges: (rightSide: Array<T>) => void;
  getOptionLabel: (o: T) => string;
  getOptionSelected: (selected: T, option: T) => boolean;
};

function TransferList<T>(props: TransferListProps<T>): JSX.Element {
  const [selectedItems, setSelectedItems] = React.useState<Array<T>>(
    props.selected
  );

  useEffect(() => {
    setSelectedItems(props.selected);
  }, [props.selected]);

  useEffect(() => {
    props.onChanges(selectedItems);
  }, [selectedItems]);

  return (
    <Autocomplete
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      multiple
      value={selectedItems}
      onChange={(event, newValue) => setSelectedItems(newValue as T[])}
      options={props.options}
      getOptionLabel={(o) => props.getOptionLabel(o)}
      isOptionEqualToValue={(o, value) => {
        return (
          selectedItems.findIndex((s) => props.getOptionSelected(s, o)) >= 0
        );
      }}
      renderTags={(tagValue, getTagProps) =>
        selectedItems.map((option, index) => (
          <Chip
            label={props.getOptionLabel(option)}
            {...getTagProps({ index })}
            key={`chip_key_${index}`}
          />
        ))
      }
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params as TextFieldProps}
          label={props.fieldLabel}
          variant="outlined"
          placeholder={props.placeholder}
          error={props.error}
        />
      )}
    ></Autocomplete>
  );
}

export default TransferList;
