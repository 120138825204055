import React, { lazy } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useUser } from "../../actions/user.actions";
import NavigationHelper from "../../helpers/navigation-helper";
import FacebookAuthCallback from "../../pages/User/FacebookAuthCallback";
import { RouteType } from "../../routes";

const CompleteUserRegistration = lazy(() => import("../../pages/CompleteRegistration"));
const CommercialCompleteUserRegistration = lazy(() => import("../../pages/CommercialCompleteRegistration"));
const CommuneClimatePlan = lazy(() => import("../../pages/MyClimatePlanPage"));
const MyHouseStartPage = lazy(() => import("../../pages/MyHouseStartPage/MyHouseStartPage"));
const CommercialClimatePlan = lazy(() => import("../../pages/MyClimatePlanPage/pages/ClimatePlanCommercialStartPage"));
const ClubsStartPage = lazy(() => import("../../pages/Clubs/ClubsStartPage"));
const ClubsChristmasBox = lazy(() => import("../../pages/Clubs/ClubsChristmasBox"));
const ClubsSubscribeIntroPage = lazy(() => import("../../pages/Clubs/ClubsSubscribeIntroPage"));
const ClubsPaymantPage = lazy(() => import("../../pages/Clubs/ClubsPaymentPage"));
const NewsDetailsPage = lazy(() => import("../../pages/NewsStream/NewsDetailsPage"));
const ArticlePage = lazy(() => import("../../pages/Clubs/ArticlePage"));
const CO2CalculatorPageLazy = lazy(() => import("../../pages/ClimatePlan/MyHouseClimatePlan"));
const CustomLandingPageLazy = lazy(() => import("../../pages/LandingPage/CustomLandingPage"));
const LandingPageLazy = lazy(() => import("../../pages/LandingPage/LandingPage"));
const LandingLoaderLazy = lazy(() => import("../../pages/LandingPage/LandingLoader"));
const SustainabilityCheckPageLazy = lazy(() => import("../../pages/SustainabilityCheck/SustainabilityCheckPage"));

export const pagesRoutes: RouteType[] = (() => {
  return [
    { path: "landing/:name/:languageId", element: CustomLandingPageLazy, allowAnonymous: true },
    { path: "landing/:name", element: CustomLandingPageLazy, allowAnonymous: true },
    { path: "landing", element: LandingPageLazy, allowAnonymous: true },
    { path: "landing-loader", element: LandingLoaderLazy, allowAnonymous: true },
    {
      path: "",
      element: NavigationHelper.IsClimateSite() ? CommercialClimatePlan : MyHouseStartPage,
      allowAnonymous: true,
    },
    { path: "klimaplan", element: CommuneClimatePlan, allowAnonymous: true },
    { path: "sustainabilitycheck", element: SustainabilityCheckPageLazy, allowAnonymous: true },
    {
      path: "facebook-authenticated-callback/:facebookKey",
      element: FacebookAuthCallback,
      allowAnonymous: true,
    },
    { path: "completeregistration", element: CompleteUserRegistration },
    {
      path: "commercial-completeregistration",
      element: CommercialCompleteUserRegistration,
    },
    { path: "/co2calculator", element: CO2CalculatorPageLazy },
    { path: "/articles", element: ClubsStartPage, allowAnonymous: true },
    {
      path: "/clubs-subscribe",
      element: ClubsSubscribeIntroPage,
      allowAnonymous: true,
    },
    { path: "/christmas-box", element: ClubsChristmasBox, allowAnonymous: true },
    { path: "/clubs-payment", element: ClubsPaymantPage, allowAnonymous: true },
    { path: "/style", element: ClubsStartPage, allowAnonymous: true },
    { path: "/community", element: ClubsStartPage, allowAnonymous: true },
    { path: "/news/:newsId", element: NewsDetailsPage, allowAnonymous: true },
    { path: "/articles/:articleName", element: ArticlePage, allowAnonymous: true },
  ];
})();

const BaseLayout = (): JSX.Element => {
  const { pathname } = useLocation();
  const [{ uiMode }, userActions] = useUser();

  React.useEffect(() => {
    if (uiMode !== "unit") {
      userActions.setUiMode("unit");
    }
  }, [pathname]);

  return <Outlet />;
};

export default BaseLayout;
