export const ServiceBookActions = {
  GET_REPORTS: "GET_REPORTS",
  GET_REPORTS_SUCCEEDED: "GET_REPORTS_SUCCEEDED",
  GET_REPORT: "GET_REPORT",
  GET_REPORT_SUCCEEDED: "GET_REPORT_SUCCEEDED",
  SEND_OFFER_REQUEST: "SEND_OFFER_REQUEST",
  SEND_OFFER_REQUEST_SUCCEEDED: "SEND_OFFER_REQUEST_SUCCEEDED",
  SET_ITEMS_TO_FIX: "SET_ITEMS_TO_FIX",
  GET_UNIT_TASKS: "GET_UNIT_TASKS",
  GET_UNIT_TASKS_SUCCEEDED: "GET_UNIT_TASKS_SUCCEEDED",
  SET_TASK_STATUS: "SET_TASK_STATUS",
  SET_TASK_STATUS_SUCCEEDED: "SET_TASK_STATUS_SUCCEEDED",
  SET_TASKLIST_STATUS: "SET_TASKLIST_STATUS",
  SET_TASKLIST_STATUS_SUCCEEDED: "SET_TASKLIST_STATUS_SUCCEEDED",
  SERVICEBOOK_ACTION_FAILED: "SERVICEBOOK_ACTION_FAILED",
  ACCEPT_OFFER: "ACCEPT_OFFER",
  ACCEPT_OFFER_SUCCEEDED: "ACCEPT_OFFER_SUCCEEDED",
  REJECT_OFFER: "REJECT_OFFER",
  REJECT_OFFER_SUCCEEDED: "REJECT_OFFER_SUCCEEDED",
  CLEAR_ERROR: "CLEAR_ERROR",
  GET_UNIT_OFFERS: "GET_UNIT_OFFERS",
  GET_UNIT_OFFERS_SUCCEEDED: "GET_UNIT_OFFERS_SUCCEEDED",
  SET_CUSTOMER_REPORT_STATE: "SET_CUSTOMER_REPORT_STATE",
  GET_LATEST_REPORT: "GET_LATEST_REPORT",
  GET_LATEST_REPORT_SUCCEEDED: "GET_LATEST_REPORT_SUCCEEDED",
  REQUEST_CONSENT: "REQUEST_CONSENT",
  REQUEST_CONSENT_SUCCEEDED: "REQUEST_CONSENT_SUCCEEDED",
  GIVE_CONSENT: "GIVE_CONSENT",
  GIVE_CONSENT_SUCCEEDED: "GIVE_CONSENT_SUCCEEDED"
};
