import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { ThemeProvider } from "@emotion/react";
import { communeTheme } from "../../constants/theme";
import { RouteType } from "../../routes";

const Layout = lazy(() => import("./MyClimatePlanPageLayout"));
const ClimatePlanArticlePageLazy = lazy(() => import("./pages/ClimatePlanArticlePage"));
const ClientPlanStartPageLazy = lazy(() => import("./pages/ClientsStartPage"));
const StartSustainabilityLazy = lazy(() => import("./pages/StartSustainabilityPage"));
const ClimatePlanStartPageLazy = lazy(() => import("./pages/ClimatePlanStartPage"));
const AboutStartPageLazy = lazy(() => import("./pages/AboutStartPage"));
const PressStartPageLazy = lazy(() => import("./pages/MediaStartPage"));
const TopMunicipalityPageLazy = lazy(() => import("./pages/TopMunicipalityPage"));
const TeamPageLazy = lazy(() => import("./pages/TeamPage"));

export const klimaplanRoutes: RouteType[] = (() => {
  return [
    { title: "", path: "", element: ClimatePlanStartPageLazy },
    { title: "", path: "sustainability", element: StartSustainabilityLazy },
    { title: "", path: "clients", element: ClientPlanStartPageLazy },
    { title: "", path: "about-start", element: AboutStartPageLazy },
    { title: "", path: "media/:uniqueName", element: ClimatePlanArticlePageLazy },
    { title: "", path: "media", element: PressStartPageLazy },
    { title: "", path: "top-municipality", element: TopMunicipalityPageLazy },
    { title: "", path: "team", element: TeamPageLazy },
  ];
})();

const MyClimatePlanLayout = (): JSX.Element => {
  return (
    <ThemeProvider theme={communeTheme}>
      <Layout>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Layout>
    </ThemeProvider>
  );
};

export default MyClimatePlanLayout;
